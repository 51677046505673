@tailwind base;
@tailwind components;
@tailwind utilities;
/* @import "react-paginate/dist/react-paginate.css"; */


*{
  margin:0;
  padding:0;
  box-sizing: border-box;
}
/* Add this to your stylesheet */
li.active {
  background-color: #2d3748; 
  position: relative;
}
li.active::after{
  position: absolute;
  width: 4px;
  height: 100%;
  left: 0;
  background:greenyellow;
}
.pagination{
  display: flex;
  gap:10px;
}
.switch-case-container {
  width: 100%;
  padding: 20px;
  background-color: #1f2937;
  border-radius: 8px;
}

.switch-case-buttons {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.switch-case-button {
  cursor: pointer;
  padding: 8px 16px;
  margin-right: 4px;
  background-color: #555555;
  border-radius: 4px;
  color: #ffffff;
  transition: background-color 0.3s ease;
}

.switch-case-button:hover {
  background-color: #777777;
}

.switch-case-button.active {
  background-color: #007bff; /* Change to your desired active color */
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 20px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}
